import React, { useEffect, createRef } from 'react'
import lottie from 'lottie-web'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import animationData from './Loading_200x330.json'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
}))

function SplashScreenAnimated() {
  const classes = useStyles()
  const animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData,
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    })
    return () => anim.destroy()
  }, [animationContainer])

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={6}>
        <div id="detooo-logo" ref={animationContainer} />
      </Box>
    </div>
  )
}

export default SplashScreenAnimated
