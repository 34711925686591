import { lazy } from 'react'
import {
  getTestimonials,
  getTestimonyById,
  changeTestimonyVisibility,
  deleteTestimony,
} from '../services/shop'

const shopRoutes = [
  {
    exact: true,
    path: '/shop/products/create',
    component: lazy(() =>
      import('src/views/management/shop/ProductCreateView')
    ),
  },
  {
    exact: true,
    path: '/shop/testimonials',
    service: getTestimonials,
    serviceChangeStatus: changeTestimonyVisibility,
    serviceDelete: deleteTestimony,
    component: lazy(() => import('src/views/management/TestimonialsListView')),
  },
  {
    exact: true,
    path: '/shop/testimonials/:testimonyId',
    service: getTestimonyById,
    component: lazy(() => import('src/views/management/TestimonyDetailsView')),
  },
  {
    exact: true,
    path: '/shop/carts',
    component: lazy(() => import('src/views/management/shop/CartListView')),
  },
  {
    exact: true,
    path: '/shop/products/:productId',
    component: lazy(() =>
      import('src/views/management/shop/ProductDetailsView')
    ),
  },
  // {
  //   exact: true,
  //   path: '/shop/payments/:paymentId',
  //   component: lazy(() =>
  //     import('src/views/management/shop/ShopPaymentDetailsView')
  //   ),
  // },
  {
    exact: true,
    path: '/shop/categories/create',
    component: lazy(() =>
      import('src/views/management/shop/CategoryCreateView')
    ),
  },
  {
    exact: true,
    path: '/shop/products/:productId/edit',
    component: lazy(() => import('src/views/management/shop/ProductEditView')),
  },
  {
    exact: true,
    path: '/shop/categories',
    component: lazy(() => import('src/views/management/shop/CategoryListView')),
  },
  {
    exact: true,
    path: '/shop/categories/:categoryId',
    component: lazy(() =>
      import('src/views/management/shop/CategoryDetailsView')
    ),
  },
  {
    exact: true,
    path: '/shop/categories/:categoryId/edit',
    component: lazy(() => import('src/views/management/shop/CategoryEditView')),
  },
  {
    exact: true,
    path: '/shop/products',
    component: lazy(() => import('src/views/management/shop/ProductListView')),
  },
  // {
  //   exact: true,
  //   path: '/categories/create',
  //   component: lazy(() =>
  //     import('src/views/management/shop/ShopCategoryCreateView')
  //   ),
  // },
  // {
  //   exact: true,
  //   path: '/shop/orders',
  //   component: lazy(() =>
  //     import('src/views/management/shop/ShopOrderListView')
  //   ),
  // },
  // {
  //   exact: true,
  //   path: '/shop/orders/:orderId',
  //   component: lazy(() =>
  //     import('src/views/management/shop/ShopOrderDetailsView')
  //   ),
  // },
]

export default shopRoutes
