import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import LocationIcon from '@mui/icons-material/LocationOnOutlined'
import PeopleIcon from '@mui/icons-material/PeopleOutlined'
import ShieldIcon from '@mui/icons-material/VerifiedUserOutlined'
import PhoneIcon from '@mui/icons-material/PhoneIphoneOutlined'
import PaymentIcon from '@mui/icons-material/PaymentOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import SellIcon from '@mui/icons-material/SellOutlined'
import DocsIcon from '@mui/icons-material/ArticleOutlined'

export const navConfig = [
  {
    items: [
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        href: '/reports/dashboard',
      },
      {
        title: 'Config',
        icon: SettingsIcon,
        href: '/reports/config',
        items: [
          {
            title: 'General',
            href: '/reports/config',
          },
          {
            title: 'Apps',
            href: '/apps',
          },
          {
            title: 'Client Types',
            href: '/client-types',
          },
          {
            title: 'Currencies',
            href: '/currencies',
          },
        ],
      },
      {
        title: 'Docs',
        icon: DocsIcon,
        href: '/docs',
      },
      {
        title: 'Provinces',
        icon: LocationIcon,
        href: '/provinces/',
      },
      {
        title: 'Role',
        icon: ShieldIcon,
        href: '/roles',
      },
      {
        title: 'Customers',
        icon: PeopleIcon,
        href: '/customers',
      },
    ],
  },
  {
    items: [
      {
        title: 'Ads',
        icon: SellIcon,
        href: '/ads',
        items: [
          {
            title: 'Categories',
            href: '/ads/categories',
          },
          {
            title: 'Plans',
            href: '/plans',
          },
          {
            title: 'Ads',
            href: '/ads',
          },
          {
            title: 'Comments',
            href: '/ads/comments',
          },
          {
            title: 'Reports',
            href: '/ads/reports',
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Recharges',
        icon: PhoneIcon,
        href: '/topups',
        items: [
          {
            title: 'Promotions',
            href: '/topups/promotions',
          },
          {
            title: 'Packages',
            href: '/topups/packages',
          },
          {
            title: 'Testimonials',
            href: '/topups/testimonials',
          },
          {
            title: 'Subscriptions',
            href: '/topups/subscriptions',
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Shop',
        icon: ShoppingCartIcon,
        href: '/shop',
        items: [
          {
            title: 'Categories',
            href: '/shop/categories',
          },
          {
            title: 'Products',
            href: '/shop/products',
          },
          {
            title: 'Testimonials',
            href: '/shop/testimonials',
          },
          {
            title: 'Carts Saved',
            href: '/shop/carts',
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Orders',
        icon: ReceiptOutlinedIcon,
        href: '/orders',
      },
    ],
  },
  {
    items: [
      {
        title: 'Payments',
        icon: PaymentIcon,
        href: '/payments',
      },
    ],
  },
]

export default navConfig
