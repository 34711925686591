import * as React from 'react'

function LogoSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={644.13}
      height={281.026}
      viewBox="0 0 17043 7435"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__fil0,.prefix__fil1{fill:#008dd2;fill-rule:nonzero}.prefix__fil0{fill:#a2d9f7}'
          }
        </style>
      </defs>
      <g id="prefix__Capa_x0020_1">
        <path
          className="prefix__fil0"
          d="M0 7379V2815h2322c645 0 1097 135 1357 405 259 269 389 741 389 1416v922c0 675-130 1147-389 1416-260 270-712 405-1357 405H0zm1224-1017h910c281 0 465-50 553-151 88-99 132-345 132-735v-751c0-391-44-638-133-739-89-103-273-154-552-154h-910v2530zm3629 1017V2815h3428v1017H6077v791h2140v879H6077v860h2253v1017H4853z"
        />
        <path
          className="prefix__fil1"
          d="M12965 5861c0-541 159-928 381-1163 260-275 712-412 1357-412h602c645 0 1097 137 1357 412 221 235 381 622 381 1163 0 540-160 927-381 1162-260 275-712 412-1357 412h-602c-645 0-1097-137-1357-412-222-235-381-622-381-1162zm1925-502c-278 0-461 52-550 154-54 62-113 177-113 348-1 173 57 289 111 350 88 101 272 151 552 151h227c281 0 465-50 553-151 54-61 111-177 111-350 0-171-59-286-113-348-89-102-272-154-551-154h-227zM10063 7379V3832H8889V2815h3578v1017h-1167v3547z"
        />
        <path
          fill="#008dd2"
          fillRule="nonzero"
          d="M14391 3832h-1174V2815h3579v1017h-1168z"
        />
        <path
          fill="#51b8e9"
          fillRule="nonzero"
          d="M14391 2425h-1174V1408h3579v1017h-1168z"
        />
        <path
          className="prefix__fil0"
          d="M14391 1017h-1174V0h3579v1017h-1168z"
        />
      </g>
    </svg>
  )
}

export default LogoSVG
