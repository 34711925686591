import { lazy } from 'react'
import {
  createApp,
  createClient,
  createCurrency,
  getAppById,
  getApps,
  getClientTypes,
  getCurrencies,
  getClientTypesById,
  getCurrencyById,
} from '../services/docs'

const configRoutes = [
  {
    exact: true,
    path: '/apps/create',
    service: createApp,
    fields: ['appName', 'commonName'],
    component: lazy(() => import('src/views/management/config/CreateView')),
  },
  {
    exact: true,
    path: '/apps',
    service: getApps,
    fields: ['appName', 'commonName'],
    component: lazy(() => import('src/views/management/config/ListView')),
  },
  {
    exact: true,
    path: '/apps/:itemId/edit',
    service: getAppById,
    fields: ['appName', 'commonName'],
    component: lazy(() => import('src/views/management/config/EditView')),
  },
  //  clients
  {
    exact: true,
    path: '/client-types/create',
    service: createClient,
    fields: ['clientType', 'commonName'],
    component: lazy(() => import('src/views/management/config/CreateView')),
  },
  {
    exact: true,
    path: '/client-types',
    service: getClientTypes,
    fields: ['clientType', 'commonName'],
    component: lazy(() => import('src/views/management/config/ListView')),
  },
  {
    exact: true,
    path: '/client-types/:itemId/edit',
    service: getClientTypesById,
    fields: ['clientType', 'commonName'],
    component: lazy(() => import('src/views/management/config/EditView')),
  },
  //  currencies
  {
    exact: true,
    path: '/currencies/create',
    service: createCurrency,
    fields: ['currencyCode', 'currencyName'],
    component: lazy(() => import('src/views/management/config/CreateView')),
  },
  {
    exact: true,
    path: '/currencies',
    service: getCurrencies,
    fields: ['currencyCode', 'currencyName'],
    component: lazy(() => import('src/views/management/config/ListView')),
  },
  {
    exact: true,
    path: '/currencies/:itemId/edit',
    service: getCurrencyById,
    fields: ['currencyCode', 'currencyName'],
    component: lazy(() => import('src/views/management/config/EditView')),
  },
]

export default configRoutes
