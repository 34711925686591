import { lazy } from 'react'

const customerRoutes = [
  {
    exact: true,
    path: '/customers',
    component: lazy(() =>
      import('src/views/management/customers/CustomerListView')
    ),
  },
  {
    exact: true,
    path: '/customers/create',
    component: lazy(() =>
      import('src/views/management/customers/CustomerCreateView')
    ),
  },
  {
    exact: true,
    path: '/customers/:customerId',
    component: lazy(() =>
      import('src/views/management/customers/CustomerDetailsView')
    ),
  },
  {
    exact: true,
    path: '/customers/:customerId/edit',
    component: lazy(() =>
      import('src/views/management/customers/CustomerEditView')
    ),
  },
]

export default customerRoutes
