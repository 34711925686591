import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import useSettings from 'src/hooks/useSettings'
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import MenuIcon from '@mui/icons-material/MenuOutlined'
import Logo from 'src/components/Logo'
import LogoVertical from 'src/components/LogoVertical'
import Account from './Account'
import Search from './Search'
import Settings from './Settings'
import Languages from './Languages'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 100,
    },
    [theme.breakpoints.down('lg')]: {
      zIndex: theme.zIndex.drawer,
    },
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  logo: {
    marginLeft: 40,
    width: 'auto',
    height: 36,
    opacity: 1,
    animationName: '$fadeInOpacity',
    animationIterationCount: 1,
    animationTimingFunction: 'easy-in',
    animationDuration: '2s',
  },
  logoV: {
    marginLeft: 0,
    width: 'auto',
    height: 36,
    animationName: '$fadeInOpacity',
    animationIterationCount: 1,
    animationTimingFunction: 'easy-in',
    animationDuration: '2s',
  },
  '@keyframes fadeInOpacity': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles()
  const { settings } = useSettings()

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            id="moreButton"
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
            size="large"
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/">
            <div className={classes.logoContainer}>
              {settings.drawerOpen ? (
                <Logo className={classes.logo} />
              ) : (
                <LogoVertical className={classes.logoV} />
              )}
            </div>
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Search />
        <Settings />
        <Languages />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
