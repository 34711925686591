import axios from 'axios'

const headers = {
  'Content-Type': 'application/json',
}

const instance = axios.create({
  // baseURL: process.env.REACT_APP_USERS_API_URL,
  baseURL: 'https://api.v2.users.detooo.com',
  headers,
})

export const AdsApi = axios.create({
  // baseURL: process.env.REACT_APP_ADS_API_URL,
  baseURL: 'https://api.v2.anuncios.detooo.com',
  headers,
})

export const OrdersApi = axios.create({
  // baseURL: process.env.REACT_APP_ORDERS_API_URL,
  baseURL: 'https://api.v2.orders.detooo.com',
  headers,
})

export const RechargesApi = axios.create({
  // baseURL: process.env.REACT_APP_RECHARGES_API_URL,
  baseURL: 'https://api.v2.recargas.detooo.com',
  headers,
})

export const PaymentsApi = axios.create({
  // baseURL: process.env.REACT_APP_PAYMENTS_API_URL,
  baseURL: 'https://api.v2.payments.detooo.com',
  headers,
})

export const ShopApi = axios.create({
  // baseURL: process.env.REACT_APP_SHOP_API_URL,
  baseURL: 'https://api.v2.shop.detooo.com',
  headers,
})

export const DocApi = axios.create({
  // baseURL: process.env.REACT_APP_DOC_API_URL,
  baseURL: 'https://api.v2.detooo.com',
  headers,
})

instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel
AdsApi.CancelToken = axios.CancelToken
AdsApi.isCancel = axios.isCancel
RechargesApi.CancelToken = axios.CancelToken
RechargesApi.isCancel = axios.isCancel
OrdersApi.CancelToken = axios.CancelToken
OrdersApi.isCancel = axios.isCancel
PaymentsApi.CancelToken = axios.CancelToken
PaymentsApi.isCancel = axios.isCancel
ShopApi.CancelToken = axios.CancelToken
ShopApi.isCancel = axios.isCancel
DocApi.CancelToken = axios.CancelToken
DocApi.isCancel = axios.isCancel

export default instance
