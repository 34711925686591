import React, { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import useSnackbar from 'src/hooks/useSnackbar'
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import PersonIcon from '@mui/icons-material/PersonOutlined'
import { useTranslation } from 'react-i18next'
import useUser from 'src/hooks/useUser'

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
    marginTop: 14,
  },
  icon: {
    width: 20,
    height: 20,
    margin: theme.spacing(0.5, 1, 0.5, 0),
    color: theme.palette.secondary.main,
  },
}))

function Account() {
  const classes = useStyles()
  const history = useHistory()
  const ref = useRef(null)
  const { t } = useTranslation('translation')
  const { user, logout } = useUser()
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      logout()
      history.push('/')
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={user.avatar} />
        <Hidden mdDown>
          <Typography variant="h6" color="inherit">
            {user.fullname}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/account">
          <PersonIcon className={classes.icon} />
          {t('Profile')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <img
            className={classes.icon}
            alt="Logout"
            src="/static/images/logout.svg"
          />
          {t('Logout')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default Account
