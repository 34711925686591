import React, { useState } from 'react'
import PropTypes from 'prop-types'

const UserContext = React.createContext({})

export function UserProvider({ children }) {
  const [user, setUser] = useState(null)
  const [accessToken, setAccessToken] = useState(() =>
    window.localStorage.getItem('accessToken')
  )

  const setProvince = (provinceData) => {
    setUser((prevUserData) => ({ ...prevUserData, province: provinceData }))
  }

  const setMunicipalities = (municipalitiesData) => {
    setUser((prevUserData) => ({
      ...prevUserData,
      municipalities: municipalitiesData,
    }))
  }

  return (
    <UserContext.Provider
      value={{
        user,
        accessToken,
        setUserData: setUser,
        setUserProvince: setProvince,
        setUserMunicipalities: setMunicipalities,
        setAccessToken,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserContext
