import React, { lazy, Suspense, Fragment } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import guestRoutes from './guestRoutes'
import customerRoutes from './customerRoutes'
import roleRoutes from './roleRoutes'
import adRoutes from './adRoutes'
import rechargeRoutes from './rechargeRoutes'
import paymentRoutes from './paymentRoutes'
import shopRoutes from './shopRoutes'
import configRoutes from './configRoutes'
// import packageRoutes from './packageRoutes'
// import promotionRoutes from './promotionRoutes'

const routesConfig = [
  ...guestRoutes,
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/reports/dashboard" />,
      },
      {
        exact: true,
        path: '/reports',
        component: () => <Redirect to="/reports/dashboard" />,
      },
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('src/views/pages/AccountView')),
      },
      {
        exact: true,
        path: '/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView')),
      },
      {
        exact: true,
        path: '/reports/config',
        component: lazy(() => import('src/views/pages/ConfigView')),
      },
      {
        exact: true,
        path: '/provinces',
        component: lazy(() => import('src/views/management/ProvinceListView')),
      },
      {
        exact: true,
        path: '/provinces/:id/:name',
        component: lazy(() =>
          import('src/views/management/ProvinceDetailsView')
        ),
      },
      {
        exact: true,
        path: '/docs',
        component: lazy(() => import('src/views/management/docs/DocListView')),
      },
      {
        exact: true,
        path: '/docs/create',
        component: lazy(() =>
          import('src/views/management/docs/DocCreateView')
        ),
      },
      {
        exact: true,
        path: '/docs/:docId/edit',
        component: lazy(() => import('src/views/management/docs/DocEditView')),
      },
      ...customerRoutes,
      ...roleRoutes,
      ...adRoutes,
      ...rechargeRoutes,
      ...paymentRoutes,
      ...shopRoutes,
      ...configRoutes,
      // ...packageRoutes,
      // ...promotionRoutes,
      {
        exact: true,
        path: '/orders/:orderId',
        component: lazy(() =>
          import('src/views/management/orders/OrderDetailsView')
        ),
      },
      {
        exact: true,
        path: '/orders',
        component: lazy(() =>
          import('src/views/management/orders/OrderListView')
        ),
      },
      {
        exact: true,
        path: '/management',
        component: () => <Redirect to="/customers" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
]

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment
          const Layout = route.layout || Fragment
          const Component = route.component

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : route.service ? (
                      <Component
                        service={route.service}
                        serviceChangeStatus={route.serviceChangeStatus}
                        serviceDelete={route.serviceDelete}
                        fields={route.fields || []}
                        {...props}
                      />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  ) : null

function Routes() {
  return renderRoutes(routesConfig)
}

export default Routes
