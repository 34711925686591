import { lazy } from 'react'

const adRoutes = [
  {
    exact: true,
    path: '/ads/categories',
    component: lazy(() => import('src/views/management/ads/CategoryListView')),
  },
  {
    exact: true,
    path: '/ads/categories/create',
    component: lazy(() =>
      import('src/views/management/ads/CategoryCreateView')
    ),
  },
  {
    exact: true,
    path: '/ads/categories/:categoryId',
    component: lazy(() =>
      import('src/views/management/ads/CategoryDetailsView')
    ),
  },
  {
    exact: true,
    path: '/ads/categories/:categoryId/edit',
    component: lazy(() => import('src/views/management/ads/CategoryEditView')),
  },
  {
    exact: true,
    path: '/ads',
    component: lazy(() => import('src/views/management/ads/AdListView')),
  },
  {
    exact: true,
    path: '/ads/comments',
    component: lazy(() =>
      import('src/views/management/comments/CommentListView')
    ),
  },
  {
    exact: true,
    path: '/ads/reports',
    component: lazy(() => import('src/views/management/report/ReportListView')),
  },
  // {
  //   exact: true,
  //   path: '/ads/payments',
  //   component: lazy(() =>
  //     import('src/views/management/payment/PaymentListView')
  //   ),
  // },
  {
    exact: true,
    path: '/ads/:adId',
    component: lazy(() => import('src/views/management/ads/AdDetailsView')),
  },
  {
    exact: true,
    path: '/ads/comments/:commentId',
    component: lazy(() =>
      import('src/views/management/comments/CommentDetailsView')
    ),
  },
  // {
  //   exact: true,
  //   path: '/ads/payments/:paymentId',
  //   component: lazy(() =>
  //     import('src/views/management/payment/PaymentDetailsView')
  //   ),
  // },
  {
    exact: true,
    path: '/ads/reports/:reportId',
    component: lazy(() =>
      import('src/views/management/report/ReportDetailsView')
    ),
  },
  {
    exact: true,
    path: '/plans',
    component: lazy(() => import('src/views/management/plans/PlanListView')),
  },
  {
    exact: true,
    path: '/plans/create',
    component: lazy(() => import('src/views/management/plans/PlanCreateView')),
  },
  {
    exact: true,
    path: '/plans/:planId',
    component: lazy(() => import('src/views/management/plans/PlanDetailsView')),
  },
  {
    exact: true,
    path: '/plans/:planId/edit',
    component: lazy(() => import('src/views/management/plans/PlanEditView')),
  },
]

export default adRoutes
