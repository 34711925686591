import 'react-perfect-scrollbar/dist/css/styles.css'
import 'nprogress/nprogress.css'
import { enableES5 } from 'immer'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from 'src/serviceWorker'
import { SettingsProvider } from 'src/context/SettingsContext'
import { UserProvider } from 'src/context/UserContext'
import { SnackbarProvider } from 'src/context/SnackbarContext'
import { restoreSettings } from 'src/utils/settings'
import App from 'src/App'
import { I18nextProvider } from 'react-i18next'
import i18n from 'src/i18n'

enableES5()
const settings = restoreSettings()

ReactDOM.render(
  <SettingsProvider settings={settings}>
    <UserProvider>
      <SnackbarProvider>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </SnackbarProvider>
    </UserProvider>
  </SettingsProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
