import * as React from 'react'

function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64.401}
      height={117.441}
      viewBox="0 0 1704 3107"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <style>{'.prefix__fil0{fill:#008dd2;fill-rule:nonzero}'}</style>
      </defs>
      <g id="prefix__Capa_x0020_1">
        <g id="prefix___2205402804720">
          <path
            className="prefix__fil0"
            d="M0 2449c0-226 67-387 159-486 109-115 298-172 567-172h252c269 0 458 57 567 172 92 99 159 260 159 486s-67 388-159 486c-109 115-298 172-567 172H726c-269 0-458-57-567-172-92-98-159-260-159-486zm805-209c-117 0-193 21-230 64-23 26-48 74-48 145 0 73 24 121 47 147 36 42 113 63 231 63h94c118 0 194-21 231-63 23-26 47-74 47-147 0-71-25-119-48-145-37-43-113-64-230-64h-94zM596 1601H105v-425h1496v425h-488z"
          />
          <path
            fill="#51b8e9"
            fillRule="nonzero"
            d="M596 1013H105V588h1496v425h-488z"
          />
          <path
            fill="#a2d9f7"
            fillRule="nonzero"
            d="M596 425H105V0h1496v425h-488z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
