import { lazy } from 'react'
import GuestGuard from 'src/components/GuestGuard'

const guestRoutes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
]

export default guestRoutes
