import { lazy } from 'react'
import {
  getTestimonials,
  getTestimonyById,
  changeTestimonyVisibility,
  deleteTestimony,
} from '../services/topups'

const rechargeRoutes = [
  // {
  //   exact: true,
  //   path: '/topups/show',
  //   component: lazy(() =>
  //     import('src/views/management/recharge/RechargeListView')
  //   ),
  // },
  // {
  //   exact: true,
  //   path: '/topups/show/:rechargeId',
  //   component: lazy(() =>
  //     import('src/views/management/recharge/RechargeDetailsView')
  //   ),
  // },
  {
    exact: true,
    path: '/topups/subscriptions',
    component: lazy(() =>
      import('src/views/management/topups/SubscriptionListView')
    ),
  },
  {
    exact: true,
    path: '/topups/testimonials',
    service: getTestimonials,
    serviceChangeStatus: changeTestimonyVisibility,
    serviceDelete: deleteTestimony,
    component: lazy(() => import('src/views/management/TestimonialsListView')),
  },
  {
    exact: true,
    path: '/topups/testimonials/:testimonyId',
    service: getTestimonyById,
    component: lazy(() => import('src/views/management/TestimonyDetailsView')),
  },
  {
    exact: true,
    path: '/topups/promotions',
    component: lazy(() =>
      import('src/views/management/topups/PromotionListView')
    ),
  },
  {
    exact: true,
    path: '/topups/promotions/create',
    component: lazy(() =>
      import('src/views/management/topups/PromotionCreateView')
    ),
  },
  {
    exact: true,
    path: '/topups/promotions/:promotionId',
    component: lazy(() =>
      import('src/views/management/topups/PromotionDetailsView')
    ),
  },
  {
    exact: true,
    path: '/topups/promotions/:promotionId/edit',
    component: lazy(() =>
      import('src/views/management/topups/PromotionEditView')
    ),
  },
  // {
  //   exact: true,
  //   path: '/rechargers',
  //   component: lazy(() =>
  //     import('src/views/management/recharger/RechargeListView')
  //   ),
  // },
  // {
  //   exact: true,
  //   path: '/rechargers/:rechargeId',
  //   component: lazy(() =>
  //     import('src/views/management/recharger/RechargeDetailsView')
  //   ),
  // },
  {
    exact: true,
    path: '/topups/packages',
    component: lazy(() =>
      import('src/views/management/topups/PackageListView')
    ),
  },
  {
    exact: true,
    path: '/topups/packages/create',
    component: lazy(() =>
      import('src/views/management/topups/PackageCreateView')
    ),
  },
  {
    exact: true,
    path: '/topups/packages/:packageId',
    component: lazy(() =>
      import('src/views/management/topups/PackageDetailsView')
    ),
  },
  {
    exact: true,
    path: '/topups/packages/:packageId/edit',
    component: lazy(() =>
      import('src/views/management/topups/PackageEditView')
    ),
  },
]

export default rechargeRoutes
