import { RechargesApi } from 'src/utils/axios'

export const getRechargesConfig = () => {
  return RechargesApi.get('/configs').then((response) => response.data)
}

export const getRechargesDashboard = () => {
  return RechargesApi.get('/dashboard/admin').then((response) => response.data)
}

export const getPackages = () => {
  return RechargesApi.get('/packages').then((response) => response.data)
}

export const getPackageById = (id) => {
  return RechargesApi.get(`/packages/${id}`).then((response) => response.data)
}

export const createPackage = (params) => {
  return RechargesApi.post('/packages', params)
}

export const updatePackage = ({ id, params }) => {
  return RechargesApi.put(`/packages/${id}`, params)
}

export const getRechargeTestimonials = ({ page, limit }) => {
  return RechargesApi.get(`/testimonials?page=${page + 1}&limit=${limit}`).then(
    (response) => {
      return { total: response.data.Total, items: response.data.items || [] }
    }
  )
}

export const deletePackage = (id) => {
  return RechargesApi.delete(`/packages/${id}`)
}

export const getDetoooRecharges = ({ queryUrl, page, limit }) => {
  const url = !queryUrl
    ? `list?page=${page}&limit=${limit}`
    : `filter?${queryUrl}&page=${page}&limit=${limit}`
  return RechargesApi.get(`recargas/detooo/admin/${url}`).then((response) => {
    return { total: response.data.Total, items: response.data.items || [] }
  })
}

export const getRecharges = ({ queryUrl, page, limit }) => {
  const url = !queryUrl
    ? `recharges/list/all?page=${page}&limit=${limit}`
    : `filters/admin?${queryUrl}&page=${page}&limit=${limit}`
  return RechargesApi.get(url).then((response) => {
    return {
      total: response.data.Total,
      items: response.data.items || response.data.Recargas,
    }
  })
}

export const getRechargeById = (id) => {
  return RechargesApi.get(`/recargas/show/${id}`).then(
    (response) => response.data
  )
}

export const getDetoooRechargeById = (id) => {
  return RechargesApi.get(`/recargas/detooo/admin/show/${id}`).then(
    (response) => response.data
  )
}

export const getTestimonials = ({ page, limit }) => {
  return RechargesApi.get(`/testimonials?page=${page}&limit=${limit}`).then(
    (response) => response.data
  )
}

export const getTestimonyById = (id) => {
  return RechargesApi.get(`/testimonials/${id}`).then(
    (response) => response.data
  )
}

export const filterTestimonials = ({ queryUrl, page, limit }) => {
  return RechargesApi.get(
    `/testimonials?${queryUrl}&page=${page + 1}&limit=${limit}`
  ).then((response) => {
    return {
      total: response.data.Total,
      items: response.data.items || [],
    }
  })
}

export const getPaymentGateways = () => {
  return RechargesApi.get('/pasarelas').then((response) => {
    return { total: response.data.length, items: response.data }
  })
}

export const getPromotions = () => {
  return RechargesApi.get('/promotions').then((response) => response.data)
}

export const getPromotionById = (id) => {
  return RechargesApi.get(`/promotions/${id}`).then((response) => response.data)
}

export const createPromotion = (params) => {
  return RechargesApi.post('/promotions', params)
}

export const updatePromotion = ({ id, params }) => {
  return RechargesApi.put(`/promotions/${id}`, params)
}

export const sendEmailPromotion = (params) => {
  return RechargesApi.post('/email/promo', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const updateEditConfigs = (params) => {
  return RechargesApi.put('/configs', params)
}

export const changeTestimonyVisibility = (testimonialId) => {
  return RechargesApi.patch(`/testimonials/${testimonialId}`)
}

export const deleteTestimony = (id) => {
  return RechargesApi.delete(`/testimonials/${id}`)
}

export const deletePromotion = (id) => {
  return RechargesApi.delete(`/promotions/${id}`)
}

export const deleteGateway = (id) => {
  return RechargesApi.delete(`/pasarelas/${id}`)
}

export const getRechargerProfile = (id) => {
  return RechargesApi.get(`/profile/${id}`).then((response) => response.data)
}

export const createRechargerProfile = (params) => {
  return RechargesApi.post('/profile', params)
}

export const addBalanceToRecharger = ({ id, params }) => {
  return RechargesApi.patch(`/profile/recargar_saldo/${id}`, params)
}

export const removeRechargerBalance = ({ id, params }) => {
  return RechargesApi.patch(`/profile/rest_to_general/${id}`, params)
}

export const resetRechargerBalance = (id) => {
  return RechargesApi.get(`/profile/reset_from_user_general/${id}`)
}

export const resetGeneralBalance = () => {
  return RechargesApi.get('/profile/reset_general_balance')
}

export const changeRechargerStatus = (id) => {
  return RechargesApi.get(`/profile/status/${id}`)
}

export default getRechargesDashboard
