import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { create } from 'jss'
import rtl from 'jss-rtl'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import {
  createStyles,
  jssPreset,
  StylesProvider,
  makeStyles,
} from '@mui/styles'
import Auth from 'src/components/Auth'
import ScrollReset from 'src/components/ScrollReset'
import useSettings from 'src/hooks/useSettings'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createMuiTheme } from 'src/theme'
import Routes from 'src/routes'

const history = createBrowserHistory()
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  useStyles()

  const { settings } = useSettings()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createMuiTheme(settings)}>
        <StylesProvider jss={jss}>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router history={history}>
                <Auth>
                  <ScrollReset />
                  <Routes />
                </Auth>
              </Router>
            </LocalizationProvider>
          </QueryClientProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
