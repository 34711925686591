import axios from 'src/utils/axios'

export const getMunicipalitiesByProvince = (province) => {
  return axios.get(`/municipios/provincia/${province}`).then((response) => {
    return response.data
  })
}

export const getMunicipalities = () => {
  return axios.get('/municipios').then((response) => {
    return response.data
  })
}

export const updateMunicipality = (id, params) => {
  return axios.put(`/municipios/${id}`, params).then((response) => response)
}

export const changeDelivery = (id) => {
  return axios
    .patch(`/municipios/shop_delivery/${id}`)
    .then((response) => response)
}

export default getMunicipalities
