import { ShopApi } from 'src/utils/axios'

export const getCategories = () => {
  return ShopApi.get('/categories').then((response) => response.data)
}

export const getCategoryById = (id) => {
  return ShopApi.get(`/categories/${id}`).then((response) => response.data)
}

export const createCategory = (params) => {
  return ShopApi.post('/categories', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getAllOrders = ({ filters, page, limit }) => {
  let url = `/orders/admin/list?page=${page + 1}&limit=${limit}`
  if (filters) {
    Object.entries(filters).forEach(([filter, value]) => {
      if (value) {
        url += `&${filter}=${value}`
      }
    })
  }

  return ShopApi.get(url)
    .then((response) => {
      return { total: response.data.Total, items: response.data.items }
    })
    .catch((error) => {
      throw new Error(error.response.statusText)
    })
}

export const getOrderById = (id) => {
  return ShopApi.get(`/orders/admin/show/${id}`).then(
    (response) => response.data
  )
}

export const changeTestimonyVisibility = (id) => {
  return ShopApi.patch(`testimonials/change_status/${id}`)
}
export const changeOrderStatus = (status, id) => {
  return ShopApi.patch(`orders/admin/change_status/${id}`, { status }).then(
    (response) => response.data
  )
}

export const getProducts = ({ filterUrl, page, limit }) => {
  let url = '/products'

  if (filterUrl) {
    url += `/provincia/${filterUrl}&`
  } else {
    url += '?'
  }

  url += `page=${page + 1}&limit=${limit}`

  return ShopApi.get(url).then((response) => response.data)
}

export const getProductById = (id) => {
  return ShopApi.get(`/products/${id}`).then((response) => response.data)
}

export const createProduct = (params) => {
  return ShopApi.post(`/products`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const updateProduct = ({ id, params }) => {
  return ShopApi.put(`/products/${id}`, params)
}

export const updateStockStatus = (id) => {
  return ShopApi.patch(`/products/change_stock_status/${id}`)
}

export const updateProductThumbnail = ({ id, params }) => {
  return ShopApi.patch(`/products/change_thumbnail/${id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const deleteProduct = (id) => {
  return ShopApi.delete(`/products/${id}`)
}

export const deleteTestimony = (id) => {
  return ShopApi.delete(`/testimonials/${id}`)
}

export const deleteProductCategory = (id) => {
  return ShopApi.delete(`/categories/${id}`)
}

export const addImageToProduct = ({ id, images }) => {
  return ShopApi.path(`/products/add_images/${id}`, images, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const removeImageProduct = ({ id, image }) => {
  return ShopApi.path(`/products/remove_image/${id}`, image)
}

export const getShippingAddressById = (id) => {
  return ShopApi.get(`/shipping_address/admin/${id}`).then(
    (response) => response.data
  )
}

export const updateCategory = ({ id, params }) => {
  return ShopApi.put(`/categories/${id}`, params).then((response) => response)
}

export const updateCategoryThumbnail = ({ id, thumbnail }) => {
  return ShopApi.patch(`/categories/change_thumbnail/${id}`, thumbnail).then(
    (response) => response
  )
}

export const getTestimonials = ({ page, limit }) => {
  return ShopApi.get(
    `/testimonials/filter?page=${page + 1}&limit=${limit}`
  ).then((response) => response.data)
}

export const getTestimonyById = (id) => {
  return ShopApi.get(`/testimonials/${id}`).then((response) => response.data)
}

export const getShopDashboard = () => {
  return ShopApi.get('/dashboard').then((response) => response.data)
}

export const getCustomersCart = () => {
  return ShopApi.get('/cart').then((response) => response.data)
}

export const deleteCustomerCart = (id) => {
  return ShopApi.delete(`/cart/${id}`)
}
