import axios from 'src/utils/axios'

export const getPaginatedProvinces = () => {
  return axios.get('/provincias/paginated').then((response) => {
    return { total: response.data.Total, items: response.items || [] }
  })
}

export const getProvinces = () => {
  return axios.get('/provincias').then((response) => response.data)
}

export const getProvinceById = (id) => {
  return axios.get(`/provincias/${id}`).then((response) => response.data)
}

export const getProvincesNames = () => {
  getPaginatedProvinces().then((response) => {
    const prov = response.data.map((res) => ({ ...res, name: res.nombre }))
    return prov
  })
}

export default getPaginatedProvinces
