import { lazy } from 'react'

const paymentRoutes = [
  {
    exact: true,
    path: '/payments',
    component: lazy(() =>
      import('src/views/management/payments/PaymentListView')
    ),
  },
  {
    exact: true,
    path: '/payments/:paymentId',
    component: lazy(() =>
      import('src/views/management/payments/PaymentDetailsView')
    ),
  },
  // {
  //   exact: true,
  //   path: '/payment-gateways',
  //   component: lazy(() =>
  //     import('src/views/management/gateway/GatewayListView')
  //   ),
  // },
  // {
  //   exact: true,
  //   path: '/payment-gateways/create',
  //   component: lazy(() =>
  //     import('src/views/management/gateway/GatewayCreateView')
  //   ),
  // },
  // {
  //   exact: true,
  //   path: '/payment-gateways/:gatewayId/edit',
  //   component: lazy(() =>
  //     import('src/views/management/gateway/GatewayEditView')
  //   ),
  // },
]

export default paymentRoutes
