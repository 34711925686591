import { lazy } from 'react'

const roleRoutes = [
  {
    exact: true,
    path: '/roles',
    component: lazy(() => import('src/views/management/roles/RoleListView')),
  },
  {
    exact: true,
    path: '/roles/create',
    component: lazy(() => import('src/views/management/roles/RoleCreateView')),
  },
  {
    exact: true,
    path: '/roles/:roleId',
    component: lazy(() => import('src/views/management/roles/RoleDetailsView')),
  },
  {
    exact: true,
    path: '/roles/:roleId/edit',
    component: lazy(() => import('src/views/management/roles/RoleEditView')),
  },
]

export default roleRoutes
