/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useLocation, matchPath } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import useSettings from 'src/hooks/useSettings'
import PropTypes from 'prop-types'
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  IconButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import PinIcon from '@mui/icons-material/PushPinOutlined'
import LogoVertical from 'src/components/LogoVertical'
import navConfig from './NavConfig'
import NavItem from './NavItem'

function renderNavItems(tran, { items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes(tran, { acc, item, ...rest }),
        []
      )}
    </List>
  )
}

function reduceChildRoutes(tran, { acc, pathname, item, depth = 0 }) {
  const key = item.title + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={tran(item.title)}
      >
        {renderNavItems(tran, {
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={tran(item.title)}
      />
    )
  }

  return acc
}

const drawerWidth = 256

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: drawerWidth,
  },
  desktopDrawer: {
    width: drawerWidth,
    // top: 164,
    height: 'calc(100% - 64px)',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    borderRight: 'none',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  toolbarButton: {
    position: 'absolute',
    top: 65,
    left: 206,
    padding: 5,
    borderRadius: '5%',
    backgroundColor: theme.palette.background.dark,
  },
  rotateButton: {
    transform: 'rotate(90deg)',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 15,
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(6),
    },
  },
  drawerOpen: {
    width: drawerWidth,
    borderRight: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    borderRight: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
}))

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles()
  const location = useLocation()
  const { t } = useTranslation('navbar')
  const { saveSettings } = useSettings()
  const [open, setOpen] = useState(false)
  const [fixedDrawer, setFixedDrawer] = useState(false)
  const [openOnMouseOver, setOpenOnMouseOver] = useState(false)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  useEffect(() => {
    if (openMobile) {
      saveSettings({ drawerOpen: true })
    } else {
      saveSettings({ drawerOpen: false })
    }
  }, [openMobile])

  const handleDrawerFix = () => {
    setFixedDrawer((prev) => !prev)
  }

  const handleMouseOver = () => {
    if (!fixedDrawer && !open) {
      setOpenOnMouseOver(true)
      setOpen(true)
      saveSettings({ drawerOpen: true })
    }
  }

  const handleMouseOut = () => {
    if (!fixedDrawer && openOnMouseOver) {
      setOpen(false)
      setOpenOnMouseOver(false)
      saveSettings({ drawerOpen: false })
    }
  }

  const content = (
    <Box className={classes.content}>
      <Hidden lgUp>
        <Box p={2} display="flex" justifyContent="center">
          <RouterLink to="/">
            <LogoVertical height={60} />
          </RouterLink>
        </Box>
      </Hidden>

      <Box p={2} mt={3}>
        <Hidden lgDown>
          <IconButton
            className={clsx(classes.toolbarButton, {
              [classes.rotateButton]: !fixedDrawer,
            })}
            color="primary"
            onClick={handleDrawerFix}
          >
            {open && <PinIcon fontSize="small" />}
          </IconButton>
        </Hidden>
        {navConfig.map((config, idx) => (
          <List
            key={idx}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {t(config.subheader)}
              </ListSubheader>
            }
          >
            {renderNavItems(t, {
              items: config.items,
              pathname: location.pathname,
            })}
          </List>
        ))}
      </Box>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
          className={clsx(classes.desktopDrawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
