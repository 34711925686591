import { useCallback, useContext } from 'react'
import Context from 'src/context/UserContext'
import authService from 'src/services/authService'
import { login as loginService, getProfile } from 'src/services/users'
import { getProvinceById } from 'src/services/provinces'
import { getMunicipalitiesByProvince } from 'src/services/municipalities'

export default function useUser() {
  const {
    user,
    accessToken,
    setUserData,
    setAccessToken,
    setUserProvince,
    setUserMunicipalities,
  } = useContext(Context)

  const login = useCallback(
    async ({ username, password }) => {
      await loginService({ username, password })
        .then(
          // eslint-disable-next-line camelcase
          ({ user, access_token }) =>
            new Promise((resolve, reject) => {
              if (!user.role.includes('ADMIN')) {
                throw new Error('Access denied')
              } else {
                setAccessToken(access_token)
                setUserData(user)
                getProvinceById(user.provincia).then(setUserProvince)
                getMunicipalitiesByProvince(user.provincia).then(
                  setUserMunicipalities
                )
                authService.setSession(access_token)
                window.localStorage.setItem('accessToken', access_token)
              }
            })
        )
        .catch((error) => {
          window.localStorage.removeItem('accessToken')
          throw error
        })
    },
    [setAccessToken]
  )

  const loginInWithToken = useCallback(async () => {
    await getProfile()
      .then((user) => {
        if (!user.role.includes('ADMIN')) {
          throw new Error('Access denied')
        } else {
          setUserData(user)
          getProvinceById(user.provincia).then(setUserProvince)
          getMunicipalitiesByProvince(user.provincia).then(
            setUserMunicipalities
          )
        }
      })
      .catch((error) => {
        throw error
      })
  }, [])

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken')
    setAccessToken(null)
    setUserData(null)
  }, [setAccessToken])

  return {
    isLogged: Boolean(accessToken),
    setUserData,
    setUserProvince,
    setUserMunicipalities,
    user,
    login,
    loginInWithToken,
    logout,
  }
}
