import { DocApi } from 'src/utils/axios'

export const getDocs = () => {
  return DocApi.get('/docs').then((response) => response.data)
}

export const getApps = () => {
  return DocApi.get('/apps').then((response) => response.data)
}

export const getCurrencies = () => {
  return DocApi.get('/currencies').then((response) => response.data)
}

export const getClientTypes = () => {
  return DocApi.get('/client-types').then((response) => response.data)
}

export const getClientTypesById = (id) => {
  return DocApi.get(`/client-types/${id}`).then((response) => response.data)
}

export const getDocById = (id) => {
  return DocApi.get(`/docs/${id}`).then((response) => response.data)
}

export const getCurrencyById = (id) => {
  return DocApi.get(`/currencies/${id}`).then((response) => response.data)
}

export const getAppById = (id) => {
  return DocApi.get(`/apps/${id}`).then((response) => response.data)
}

export const filterDocs = (filterUrl) => {
  return DocApi.get(`/docs/filter?${filterUrl}`).then(
    (response) => response.data
  )
}

export const createDoc = (params) => {
  return DocApi.post('/docs', params).then((response) => response.data)
}

export const createApp = (params) => {
  return DocApi.post('/apps', params).then((response) => response.data)
}

export const createClient = (params) => {
  return DocApi.post('/client-types', params).then((response) => response.data)
}

export const createCurrency = (params) => {
  return DocApi.post('/currencies', params).then((response) => response.data)
}

export const updateDoc = ({ id, params }) => {
  return DocApi.put(`/docs/${id}`, params).then((response) => response.data)
}

export const updateApp = ({ id, params }) => {
  return DocApi.put(`/apps/${id}`, params).then((response) => response.data)
}

export const updateItem = ({ app, id, params }) => {
  return DocApi.put(`/${app}/${id}`, params).then((response) => response.data)
}

export const deleteDoc = (doc) => {
  return DocApi.delete(`/docs/${doc}`).then((response) => response.data)
}

export const deleteApp = (doc) => {
  return DocApi.delete(`/app/${doc}`).then((response) => response.data)
}

export const deleteItem = ({ app, item }) => {
  return DocApi.delete(`/${app}/${item}`).then((response) => response.data)
}

export const sendEmailAppPromotion = (params) => {
  return DocApi.post('/email/promo', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default getDocs
