import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import MuiAlert from '@mui/material/Alert'

const SnackbarContext = createContext()

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function RenderSnack({ id, message, open, handleClose, ...rest }) {
  const messageId = `message-${id}`
  const { variant } = rest.options

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': messageId,
      }}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          X
        </IconButton>,
      ]}
    >
      <Alert
        onClose={handleClose}
        severity={variant || 'info'}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

RenderSnack.propTypes = {
  id: PropTypes.number,
  message: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
}

let uniqueId = 2

export const SnackbarProvider = ({ children }) => {
  const [{ current, queue }, setState] = useState({ current: null, queue: [] })

  const createSnack = (message, options) => {
    const id = uniqueId++
    const snack = { id, message, open: true, options }

    if (current) {
      setState({ current, queue: queue.concat(snack) })
    } else {
      setState({ queue, current: snack })
    }

    return id
  }

  function handleClose() {
    setState((currentState) => ({
      ...currentState,
      current: { ...currentState.current, open: false },
    }))
    // time to snack close animation
    setTimeout(openNext, 1000)
  }

  function openNext() {
    if (queue.length) {
      setState({ current: queue[0], queue: queue.slice(1) })
    } else {
      setState({ current: null, queue: [] })
    }
  }

  return (
    <SnackbarContext.Provider value={{ enqueueSnackbar: createSnack }}>
      {current && (
        <RenderSnack key={current.id} {...current} handleClose={handleClose} />
      )}
      {children}
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const SnackbarConsumer = SnackbarContext.Consumer

export default SnackbarContext
