import axios, {
  AdsApi,
  OrdersApi,
  RechargesApi,
  PaymentsApi,
  ShopApi,
  DocApi,
} from 'src/utils/axios'

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          !error.response.config.url.includes('change_password')
        ) {
          this.setSession(null)

          if (onLogout) {
            onLogout()
          }
        }

        return Promise.reject(error)
      }
    )
  }

  handleAuthentication() {
    const accessToken = this.getAccessToken()

    if (!accessToken) {
      return
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken)
    } else {
      this.setSession(null)
    }
  }

  logout = () => {
    this.setSession(null)
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken)
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      AdsApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      OrdersApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      RechargesApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      ShopApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      PaymentsApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      DocApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
      localStorage.removeItem('accessToken')
      delete axios.defaults.headers.common.Authorization
      delete AdsApi.defaults.headers.common.Authorization
      delete OrdersApi.defaults.headers.common.Authorization
      delete RechargesApi.defaults.headers.common.Authorization
      delete PaymentsApi.defaults.headers.common.Authorization
      delete ShopApi.defaults.headers.common.Authorization
      delete DocApi.defaults.headers.common.Authorization
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken')

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false
    }

    return true
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService()

export default authService
