import React from 'react'
import useUser from 'src/hooks/useUser'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

function AuthGuard({ children }) {
  const { user } = useUser()

  if (!user) {
    return <Redirect to="/login" />
  }

  return children
}

AuthGuard.propTypes = {
  children: PropTypes.any,
}

export default AuthGuard
